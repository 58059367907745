import React, { useContext, useEffect, useState } from "react";
import "./_sidebar.scss";
import logo from "./../../Assets/wall360-light.png";
import logo1 from "./../../Assets/wall360-favicon.png";
import { useNavigate } from "react-router-dom";
import { AiOutlineLogout, AiOutlineMenu } from "react-icons/ai";
import { Button, Tooltip } from "antd";
import { AppContext } from "../../App";
import Cookies from "js-cookie";
import { pageData } from "../../utils/Contants";
import { Fetch } from "../../utils/FetchData";

function Sidebar(props) {
  const selectedColumn = window.location.pathname.split("/")[1];
  const { hideBar, updatehidebar } = useContext(AppContext);
  const { theme, updateTheme } = useContext(AppContext);
  const [privileges, setPrivileges] = useState({});
  const navigate = useNavigate();

  function Logout() {
    Cookies.remove("userLoggedIn");
    Cookies.remove("token");
    localStorage.removeItem("privileges");
    navigate("/login");
  }

  useEffect(() => {
    fetchUserPrivileges();
  }, []);

  const fetchUserPrivileges = async () => {
    const adminId = localStorage.getItem("privileges") && JSON.parse(localStorage.getItem("privileges"))?.admin_id;
    const response = await Fetch.get_privileges(adminId);
    if (response.is_active) {
      localStorage.setItem("privileges", JSON.stringify(response));
      setPrivileges(response);
    } else console.log("no privileges found");
  };  

  return (
    <div
      className={hideBar ? "sidebar2" : "sidebar"}
      style={{
        backgroundColor: theme && "#8C193F",
        // background:" rgb(140,25,63)";
        background: theme && "background-color : #8C193F",
      }}
    >
      <div className="sidebar-content">
        <div className="logo-container">
          <div className="logo">
            <img src={hideBar ? logo1 : logo} alt="" />
          </div>
          <Button
            onClick={() => {
              updatehidebar(!hideBar);
            }}
            style={{
              border: "none!important",
            }}
          >
            <AiOutlineMenu style={{ fontSize: "16px" }} />
          </Button>
        </div>
        <div className="content-container">
          <ul className="navigation-panel">
            {pageData.map((item, index) => {
              if (
                privileges?.pages &&
                Object.keys(privileges?.pages).some((priv) => item.name === priv.toLowerCase())
              ) {
                return (
                  <Tooltip placement="left" title={item.title} trigger={hideBar && "hover"} key={index}>
                    <li
                      className={
                        selectedColumn === item.name || (selectedColumn === "" && item.name === "")
                          ? "selected-column"
                          : ""
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(item.navigate);
                      }}
                      style={{
                        backgroundColor: selectedColumn === item.name && theme && "#fff",
                      }}
                    >
                      <div className="sidebar-div">
                        {item.icon}
                        <a>{item.title}</a>
                      </div>
                    </li>
                  </Tooltip>
                );
              }
            })}
          </ul>
          <Tooltip placement="left" title={"Logout"} trigger={hideBar && "hover"}>
            <div onClick={() => Logout()} className="logout-cont">
              <AiOutlineLogout style={{ fontSize: "16px" }} />
              <p>Logout</p>
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
