import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import "./App.css";
import { Suspense, lazy, useState } from "react";
import React, { createContext, useEffect } from "react";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
import Cookies from "js-cookie";
import Layout from "./Components/Layout";

const DashboardLazy = lazy(() => import("./Pages/dashboard/Dashboard"));
const AddBlogLazy = lazy(() => import("./Pages/blogs/add-blogs/AddBlog"));
const EditBlogLazy = lazy(() => import("./Pages/blogs/edit-blogs/EditBlog"));
const PropertyLazy = lazy(() => import("./Pages/properties/Properties"));
const PropertyViewLazy = lazy(() => import("./Pages/properties/property-view/PropertyView"));
const PropertyAddLazy = lazy(() => import("./Pages/properties/post-property/PostProperty"));
const UsersLazy = lazy(() => import("./Pages/users/Users"));
const UserViewLazy = lazy(() => import("./Pages/users/user-view/UserView"));
const MyUserViewLazy = lazy(() => import("./Pages/my-users/MyUsers"));
const UserActivityLazy = lazy(() => import("./Pages/users-activity/UserActivity"));
const AdminUsersLazy = lazy(() => import("./Pages/users/admin-users/AdminUsersList"));
const AdminUserViewLazy = lazy(() => import("./Pages/users/admin-users/AdminView"));
const PaymentHistoryLazy = lazy(() => import("./Pages/payment-history/PaymentHistory"));
const PlansLazy = lazy(() => import("./Pages/plans/Plans"));
const BlogLazy = lazy(() => import("./Pages/blogs/Blog"));
const BannerLazy = lazy(() => import("./Pages/banners/Banners"));
const LocalitiesLazy = lazy(() => import("./Pages/localities/Localities"));
const RequestsLazy = lazy(() => import("./Pages/requests/Requests"));
const ReportsLazy = lazy(() => import("./Pages/reports/Reports"));
const UnauthorizedLazy = lazy(() => import("./Pages/unauthorized/Unauthorized"));
const RolesLazy = lazy(() => import("./Pages/roles/Roles"));
const RoleFormLazy = lazy(() => import("./Pages/roles/role-form/RoleForm"));
const LoginLazy = lazy(() => import("./Pages/authentication/Login"));
const AddCategoryLazy = lazy(() => import("./Pages/category/AddCategory"));
const EditCategoryLazy = lazy(() => import("./Pages/category/EditCategory"));

export const ThemeContext = createContext({});
export const AppContext = createContext({});
function App() {
  const [theme, setTheme] = useState(true);
  const [hideBar, sethideBar] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  function updateTheme(boolVal) {
    setTheme((prevState) => boolVal);
  }
  function updatehidebar(boolVal) {
    sethideBar((prevState) => boolVal);
  }

  useEffect(() => {
    if (!Cookies.get("userLoggedIn")) {
      navigate("/login");
    } else if (location.pathname === "/login") {
      Cookies.remove("userLoggedIn");
    }
  }, []);

  return (
    <div className="App">
      <AppContext.Provider value={{ hideBar, updatehidebar, theme, updateTheme }}>
        <Routes>
          {Cookies.get("userLoggedIn") ? (
            <Route path="/" element={<Layout />}>
              <Route
                exact
                path="/"
                element={
                  <Suspense>
                    <DashboardLazy />
                  </Suspense>
                }
              />
              <Route exact path="*" element={<Navigate to="/" />} />
              <Route
                exact
                path="/properties"
                element={
                  <Suspense>
                    <PropertyLazy />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/properties/post-property"
                element={
                  <Suspense>
                    <PropertyAddLazy />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/properties/property_detail/:id"
                element={
                  <Suspense>
                    <PropertyViewLazy />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/admin_users/property_detail/:id"
                element={
                  <Suspense>
                    <PropertyViewLazy />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/reports/property_detail/:id"
                element={
                  <Suspense>
                    <PropertyViewLazy />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/users_activity/property_detail/:id"
                element={
                  <Suspense>
                    <PropertyViewLazy />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/users"
                element={
                  <Suspense>
                    <UsersLazy />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/my_users"
                element={
                  <Suspense>
                    <MyUserViewLazy />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/users_activity"
                element={
                  <Suspense>
                    <UserActivityLazy />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/admin_users"
                element={
                  <Suspense>
                    <AdminUsersLazy />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/admin_users/:id"
                element={
                  <Suspense>
                    <AdminUserViewLazy />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/admin-profile/:id"
                element={
                  <Suspense>
                    <AdminUserViewLazy />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/users/user-view/:id"
                element={
                  <Suspense>
                    <UserViewLazy />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/reports/user-view/:id"
                element={
                  <Suspense>
                    <UserViewLazy />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/request/user-view/:id"
                element={
                  <Suspense>
                    <UserViewLazy />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/payment_history"
                element={
                  <Suspense>
                    <PaymentHistoryLazy />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/plans"
                element={
                  <Suspense>
                    <PlansLazy />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/roles"
                element={
                  <Suspense>
                    <RolesLazy />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/roles/role-form"
                element={
                  <Suspense>
                    <RoleFormLazy />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/roles/role-form/:id"
                element={
                  <Suspense>
                    <RoleFormLazy />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/blogs"
                element={
                  <Suspense>
                    <BlogLazy />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/blogs/add-blog"
                element={
                  <Suspense>
                    <AddBlogLazy />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/blogs/edit/:blog_id"
                element={
                  <Suspense>
                    <EditBlogLazy />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/banners"
                element={
                  <Suspense>
                    <BannerLazy />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/localities"
                element={
                  <Suspense>
                    <LocalitiesLazy />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/request"
                element={
                  <Suspense>
                    <RequestsLazy />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/reports"
                element={
                  <Suspense>
                    <ReportsLazy />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/un-authorized"
                element={
                  <Suspense>
                    <UnauthorizedLazy />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/blogs/add-category"
                element={
                  <Suspense>
                    <AddCategoryLazy />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/blogs/category-list"
                element={
                  <Suspense>
                    <EditCategoryLazy />
                  </Suspense>
                }
              />
            </Route>
          ) : (
            <Route
              path="/"
              element={
                <Suspense>
                  <LoginLazy />
                </Suspense>
              }
            >
              <Route exact path="/login" element={<Navigate to="/" />} />
              <Route exact path="*" element={<Navigate to="/" />} />
            </Route>
          )}
        </Routes>
      </AppContext.Provider>
    </div>
  );
}

export default App;
