import React, { useEffect } from "react";
import Sidebar from "./sidebar/Sidebar";
import { Outlet, useNavigate } from "react-router-dom";

function Layout() {
  
  const pathName = window.location.pathname.split("/")[1];
  const privilege = localStorage.getItem("privileges") && JSON.parse(localStorage.getItem("privileges"));
  const authRoutes = Object.keys(privilege?.pages)
  .map((item) => item.toLowerCase())
  .concat("admin-profile");
  
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!authRoutes.includes(pathName) && pathName !== "") {
      navigate("/un-authorized");
    }
    if (pathName === "admin-profile") {
      const pathArr = window.location.pathname.split("/");
      const routeId = pathArr[pathArr.length - 1];
      if (privilege.admin_id !== routeId) navigate("/un-authorized");
    }
  }, [pathName]);

  return (
    <div>
      <Sidebar />
      <Outlet />
    </div>
  );
}

export default Layout;
