import { AiFillHome } from "react-icons/ai";
import { FaBlog, FaBuilding, FaLocationArrow, FaUserClock, FaUsers, FaUserSecret, FaUsersGear } from "react-icons/fa6";
import { GiKnightBanner } from "react-icons/gi";
import { MdManageAccounts, MdOutlineCardMembership, MdOutlinePayment, MdOutlineReport } from "react-icons/md";
import { RiUserSearchFill } from "react-icons/ri";

export const APP_BASE_URL = "https://backend.albionpropertyhub.com/api/";
// export const APP_BASE_URL = "https://grance.in/albion/api/";
export const IMAGE_BASE_URL = "";
export const BLOG_IMAGE_BASE_URL = "";
export const BANNER_IMAGE_BASE_URL = "";
export const PROFILE_IMAGE_BASE_URL = "";
export const BACKEND_BASE_URL = "https://backend.albionpropertyhub.com/api";
export const privileges = [
  {
    page: "dashboard",
    actions: ["analytics/show"],
  },
  {
    page: "blogs",
    actions: ["blogs/show", "blogs/create", "blogs/update", "blogs/delete"],
  },
];

export const pageData = [
  {
    name: "dashboard",
    title: "Dashboard",
    navigate: "/",
    icon: <AiFillHome style={{ fontSize: "16px" }} />,
  },
  {
    name: "properties",
    title: "Properties",
    navigate: "/properties",
    icon: <FaBuilding style={{ fontSize: "16px" }} />,
  },
  {
    name: "users",
    title: "Users",
    navigate: "/users",
    icon: <FaUsers style={{ fontSize: "16px" }} />,
  },
  {
    name: "users_activity",
    title: "User Activities",
    navigate: "/users_activity",
    icon: <RiUserSearchFill style={{ fontSize: "16px" }} />,
  },
  {
    name: "reports",
    title: "Reports",
    navigate: "/reports",
    icon: <MdOutlineReport style={{ fontSize: "16px" }} />,
  },
  {
    name: "request",
    title: "Request",
    navigate: "/request",
    icon: <FaUserClock style={{ fontSize: "16px" }} />,
  },
  {
    name: "plans",
    title: "Plans",
    navigate: "/plans",
    icon: <MdOutlineCardMembership style={{ fontSize: "16px" }} />,
  },
  {
    name: "roles",
    title: "Roles",
    navigate: "/roles",
    icon: <MdManageAccounts style={{ fontSize: "16px" }} />,
  },
  {
    name: "admin_users",
    title: "Admin Users",
    navigate: "/admin_users",
    icon: <FaUserSecret style={{ fontSize: "16px" }} />,
  },
  {
    name: "my_users",
    title: "My Users",
    navigate: "/my_users",
    icon: <FaUsersGear style={{ fontSize: "16px" }} />,
  },
  {
    name: "payment_history",
    title: "Payment History",
    navigate: "/payment_history",
    icon: <MdOutlinePayment style={{ fontSize: "16px" }} />,
  },
  {
    name: "blogs",
    title: "Blogs",
    navigate: "/blogs",
    icon: <FaBlog style={{ fontSize: "16px" }} />,
  },
  {
    name: "banners",
    title: "Banners",
    navigate: "/banners",
    icon: <GiKnightBanner style={{ fontSize: "16px" }} />,
  },
  {
    name: "localities",
    title: "Localities",
    navigate: "/localities",
    icon: <FaLocationArrow style={{ fontSize: "16px" }} />,
  },
];
